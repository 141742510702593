import React from "react";
import CDialog from "../../../components/Custom/CDialog";
import DataTable from "../../../examples/Tables/DataTable";
import HeaderOptionsSchedule from "./HeaderOptionsSchedule";
import { schedulesColumns } from "./columns";

export default function ScheduleModal({ open, close, scheduleList, addSchedule }) {
  return (
    <CDialog title="schedules" close={close} open={open} fullScreen>
      <br />
      <DataTable
        showTotalEntries={false}
        PageSize={25}
        headerOptions={
          <HeaderOptionsSchedule
            addSchedule={() => addSchedule(true)}
            onClose={() => close(false)}
          />
        }
        noEndBorder
        canSearch={false}
        table={{
          columns: schedulesColumns,
          rows: scheduleList,
        }}
      />
    </CDialog>
  );
}
