import ActiveActions from "./active.actions";
import DeviceActions from "./device.actions";
import RegisterActions from "./register.actions";
import CChipByStatus from "../../../components/Custom/CChipByStatus";

export const activeColumns = [
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "actions",
    field: "actions",
    sortable: false,
    renderCell: ({ row }) => <ActiveActions onClick={row.func} data={row} />,
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "status",
    field: "status",
    renderCell: ({ row }) => <CChipByStatus status={row.status} />,
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "driver status",
    field: "driverStatus",
    renderCell: ({ row }) => <CChipByStatus status={row.driverStatus} />,
  },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "full_name", field: "fullName" },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "dNumber", field: "dNumber" },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "version", field: "version" },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "marked paid",
    field: "autoMarkedAsPaid",
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "schedule",
    field: "withSchedule",
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "credit_card",
    field: "hasPayment",
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "update",
    field: "isVisibleUpdated",
  },
  // {
  //   align: "center",
  //   headerAlign: "center",
  //   flex: 0.2,
  //   headerName: "old",
  //   field: "old",
  // },
];
export const registerColumns = [
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "actions",
    field: "actions",
    sortable: false,
    renderCell: ({ row }) => <RegisterActions onClick={row.func} />,
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "Created At",
    field: "createdAt",
  },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "firstName",
    field: "firstName",
  },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "lastName", field: "lastName" },
  { align: "center", headerAlign: "center", flex: 0.2, headerName: "email", field: "email" },
  {
    align: "center",
    headerAlign: "center",
    flex: 0.2,
    headerName: "phoneNumber",
    field: "phoneNumber",
  },
];

export const devicesColumns = [
  {
    headerName: "actions",
    field: "actions",
    renderCell: ({ row }) => <DeviceActions onClick={row.func} data={row} />,
  },
  { headerName: "brand", field: "brand", flex: 0.2, headerAlign: "center", align: "center" },
  { headerName: "model", field: "model", flex: 0.2, headerAlign: "center", align: "center" },
  { headerName: "isTablet", field: "isTablet", flex: 0.2, headerAlign: "center", align: "center" },
  { headerName: "platform", field: "platform", flex: 0.2, headerAlign: "center", align: "center" },
  { headerName: "app", field: "app", flex: 0.2, headerAlign: "center", align: "center" },
  {
    headerName: "identifier",
    field: "uniqueId",
    flex: 0.3,
    headerAlign: "center",
    align: "center",
  },
  { headerName: "date", field: "date", flex: 0.3, headerAlign: "center", align: "center" },
];

export const schedulesColumns = [
  {
    Header: "Monday",
    accessor: "schedule",
    Cell: ({ row }) => {
      let value = row.original.schedule[0];
      if (!value) {
        return "N/A";
      }
      value[0].toString().substr(0, 5);
      return (
        <b>
          {value[0].substr(0, 5)} - {value[1].substr(0, 5)}{" "}
        </b>
      );
    },
  },
  {
    Header: "Tuesday",
    accessor: "Tuesday",
    Cell: ({ row }) => {
      let value = row.original.schedule[1];
      if (!value) {
        return "N/A";
      }
      value[0].toString().substr(0, 5);
      return (
        <b>
          {value[0].substr(0, 5)} - {value[1].substr(0, 5)}{" "}
        </b>
      );
    },
  },
  {
    Header: "Wednesday",
    accessor: "Wednesday",
    Cell: ({ row }) => {
      let value = row.original.schedule[2];
      if (!value) {
        return "N/A";
      }
      value[0].toString().substr(0, 5);
      return (
        <b>
          {value[0].substr(0, 5)} - {value[1].substr(0, 5)}{" "}
        </b>
      );
    },
  },
  {
    Header: "Thursday",
    accessor: "Thursday",
    Cell: ({ row }) => {
      let value = row.original.schedule[3];
      if (!value) {
        return "N/A";
      }
      value[0].toString().substr(0, 5);
      return (
        <b>
          {value[0].substr(0, 5)} - {value[1].substr(0, 5)}{" "}
        </b>
      );
    },
  },
  {
    Header: "Friday",
    accessor: "Friday",
    Cell: ({ row }) => {
      let value = row.original.schedule[4];
      if (!value) {
        return "N/A";
      }
      value[0].toString().substr(0, 5);
      return (
        <b>
          {value[0].substr(0, 5)} - {value[1].substr(0, 5)}{" "}
        </b>
      );
    },
  },
  {
    Header: "Saturday",
    accessor: "Saturday",
    Cell: ({ row }) => {
      let value = row.original.schedule[5];
      if (!value) {
        return "N/A";
      }
      value[0].toString().substr(0, 5);
      return (
        <b>
          {value[0].substr(0, 5)} - {value[1].substr(0, 5)}{" "}
        </b>
      );
    },
  },
  {
    Header: "Sunday",
    accessor: "Sunday",
    Cell: ({ row }) => {
      let value = row.original.schedule[6];
      if (!value) {
        return "N/A";
      }
      value[0].toString().substr(0, 5);
      return (
        <b>
          {value[0].substr(0, 5)} - {value[1].substr(0, 5)}{" "}
        </b>
      );
    },
  },
];
