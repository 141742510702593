import React from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  AlertModel,
  CompanyModel,
  DriverAppModel,
  RiderAppModel, SelectionModel,
  SettingsModel
} from "../../utilities/model";
import { firestore } from "../../firebase";
import ApiClient from "../../api";
import SwalWithField from "../../components/Custom/SwalWithField";

export default function useData() {
  const driversData = useSelector(({ driverList }) => driverList);
  const [cAlert, setCAlert] = React.useState(new AlertModel());
  const [company, setCompany] = React.useState(new CompanyModel());
  const [dashboard, setDashboard] = React.useState(new SettingsModel());
  const [selectionData, setSelectionData] = React.useState(new SelectionModel());
  const [driverApp, setDriverApp] = React.useState(new DriverAppModel());
  const [riderApp, setRiderApp] = React.useState(new RiderAppModel());
  const [loading, setLoading] = React.useState(false);
  const [locationReaderList, setLocationReaderList] = React.useState([]);
  const [dataSearchedQty, setDataSearchedQty] = React.useState(0);
  const [routingApiList, setRoutingApiList] = React.useState({
    active: "Here",
    active_dashboard: "Here",
    list: [],
    list_mapbox:[],
    list_here:[],
    list_geoapify:[]
  });
  const [loadingSuccess, setLoadingSuccess] = React.useState(false);
  const [openCAlert, setOpenCAlert] = React.useState(false);
  const ref = firestore().collection("settings");

  const getLocationReaderList = () => {
    ApiClient.get("stripe/locations")
      .then((res) => {
        setLocationReaderList(res.data.locations);
      })
      .catch((error) => {
        // Swal.fire("Error", error);
      });
  };

  React.useEffect(() => {
    getLocationReaderList();
    const DashboardSnapshot = ref.doc("dashboard").onSnapshot((querySnapshot) => {
      if (querySnapshot.data() !== undefined) {
        const data = querySnapshot.data();
        data.lat = data.mapCenter.lat;
        data.lng = data.mapCenter.lng;
        setDashboard(data);
      }
    });

    const SelectionSnapshot = ref.doc("selection").onSnapshot((querySnapshot) => {
      if (querySnapshot.data() !== undefined) {
        const data = querySnapshot.data();
        setSelectionData(data);
      }
    });

    const RoutingSnapshot = ref.doc("mapApi").onSnapshot((querySnapshot) => {
      if (querySnapshot.data() !== undefined) {
        const data = querySnapshot.data();
        if (data && Array.isArray(data.list) && data.list.length === 0) {
          ref
            .doc("mapApi")
            .update({ list: Array(31).fill("") })
            .catch((e) => {});
        }

        if (data && !data.list_mapbox) {
          ref
            .doc("mapApi")
            .update({
              list_mapbox: Array(31).fill("") ,
              list_here: Array(31).fill("") ,
              list_geoapify: Array(31).fill("")
            })
            .catch((e) => {});
        }

        setRoutingApiList(data);
      }
    });
    const DriverSnapshot = ref.doc("driver").onSnapshot((querySnapshot) => {
      if (querySnapshot.data() !== undefined) {
        const data = querySnapshot.data();
        data.lat = data.mapCenter.latitude;
        data.lng = data.mapCenter.longitude;
        data.etaRefreshTime /= 1000;
        setDriverApp(data);
      }
    });
    const RiderSnapshot = ref.doc("rider").onSnapshot((querySnapshot) => {
      if (querySnapshot.data() !== undefined) {
        const data = querySnapshot.data();
        data.lat = data.mapCenter.latitude;
        data.lng = data.mapCenter.longitude;
        setRiderApp(data);
      }
    });
    const CompanySnapshot = ref.doc("company").onSnapshot((querySnapshot) => {
      if (querySnapshot.data() !== undefined) {
        const data = querySnapshot.data();
        setCompany(data);
      }
    });

    // firestore()
    //   .collection("orders")
    //   .where("direct", "==", true)
    //   // .limit(1)
    //   .get()
    //   .then((records) => {
    //     let info = [];
    //     records.forEach((d) => {
    //       let data = d.data();
    //       let keyNames = Object.keys(data);
    //       let driverNumber = _.map(driversData, "dNumber");
    //       let isEqual = _.intersectionWith(driverNumber, keyNames, _.isEqual);
    //       console.log(isEqual);
    //       info.push(info);
    //       if (isEqual.length > 1) {
    //         console.log("isEqual", isEqual);
    //         console.log(d.id);
    //       }
    //     });
    //     console.log(info.length);
    //   });

    return () => {
      DashboardSnapshot();
      DriverSnapshot();
      RiderSnapshot();
      CompanySnapshot();
      RoutingSnapshot();
      SelectionSnapshot();
    };
  }, []);

  const onChange = (e, select, sName, model) => {
    const { name, value } = e.target;
    if (model === "company") {
      setCompany((prevState) => ({ ...prevState, [name]: value }));
    }
    if (model === "riderApp") {
      if (sName) {
        setRiderApp((prevState) => ({ ...prevState, [sName]: select }));
      } else {
        setRiderApp((prevState) => ({ ...prevState, [name]: value }));
      }
    }

    if (model === "driverApp") {
      if (sName) {
        setDriverApp((prevState) => ({ ...prevState, [sName]: select }));
      } else {
        setDriverApp((prevState) => ({ ...prevState, [name]: value }));
      }
    }

    if (model === "mapApi") {
      if (sName) {
        setRoutingApiList((prevState) => ({ ...prevState, [sName]: select }));
      } else {
        setRoutingApiList((prevState) => ({ ...prevState, [name]: value }));
      }
    }

    if (model === "dashboard") {
      if (sName) {
        setDashboard((prevState) => ({ ...prevState, [sName]: select }));
      } else {
        setDashboard((prevState) => ({ ...prevState, [name]: value }));
      }
    }

    if (model === "selection") {
      if (sName) {
        setSelectionData((prevState) => ({ ...prevState, [sName]: select }));
      } else {
        setSelectionData((prevState) => ({ ...prevState, [name]: value }));
      }
    }

  };

  const onCompanyUpdate = () => {
    setLoading(true);
    let model = { ...company };
    ref
      .doc("company")
      .set({ ...model }, { merge: true })
      .then(() => {
        setLoadingSuccess(true);
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRoutingApiUpdate = () => {
    setLoading(true);
    let model = { ...routingApiList };
    ref
      .doc("mapApi")
      .set({ ...model }, { merge: true })
      .then(() => {
        setLoadingSuccess(true);
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDashboardUpdate = async () => {
    try {
      setLoading(true);
      let model = { ...dashboard };

      model.mapCenter = {
        lat: Number(model.lat),
        lng: Number(model.lng),
      };

      delete model.lng;
      delete model.lat;

      model.autocompleteLimit = Number(model.autocompleteLimit);

      await ref.doc("dashboard").set({ ...model }, { merge: true })
      await ref.doc("selection").set({ ...selectionData, times:selectionData.times.filter(time=>time>0) }, { merge: true })

      setLoading(false);
      setLoadingSuccess(true);
    }catch (e){
      setCAlert({ open: true, type: "error", ms: e.message });
      setLoading(false);
      setLoadingSuccess(true);
    }


  };

  const onRiderUpdate = () => {
    setLoading(true);
    let model = { ...riderApp };
    model.mapCenter = {
      latitude: Number(model.lat),
      longitude: Number(model.lng),
    };
    delete model.lng;
    delete model.lat;

    model.autocompleteLimit = Number(model.autocompleteLimit);

    ref
      .doc("rider")
      .set({ ...model }, { merge: true })
      .then(() => {
        setLoadingSuccess(true);
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDriverUpdate = () => {
    setLoading(true);
    let model = { ...driverApp };
    model.mapCenter = {
      latitude: Number(model.lat),
      longitude: Number(model.lng),
    };
    model.meters = Number(model.meters);
    model.timeAfterPickup = Number(model.timeAfterPickup);
    model.timeReadyPickup = Number(model.timeReadyPickup);
    model.fareLimit = Number(model.fareLimit);
    model.geofenceRadius = Number(model.geofenceRadius);
    model.etaRefreshTime = Number(model.etaRefreshTime) * 1000;
    delete model.lng;
    delete model.lat;

    ref
      .doc("driver")
      .set({ ...model }, { merge: true })
      .then(() => {
        setLoadingSuccess(true);
      })
      .catch((e) => {
        setCAlert({ open: true, type: "error", ms: e.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeMapApi = (index, value, type) => {
    let ListApi = [...routingApiList[type]];
    ListApi[index] = value;
    setRoutingApiList((prevState) => ({ ...prevState, [type]: ListApi }));
  };

  const limpiar = async (data) => {
    if (data.length === 0) {
      return setLoading(false);
    }

    let driver = data[0];
    let driverId = driver.id;
    const colRef = firestore().collection("drivers").doc(driverId);

    if (driver.deviceInfo && driver.deviceInfo.uniqueId !== "1") {
      await colRef.update({
        isLogOut: true,
        apiKey_geoapify: firestore.FieldValue.delete(),
        apiKey_here: firestore.FieldValue.delete(),
        apiKey_mapbox: firestore.FieldValue.delete(),
        apiKey_tomtom: firestore.FieldValue.delete(),
        geolocationStatus: firestore.FieldValue.delete(),
        locationCount1: firestore.FieldValue.delete(),
        locationCount2: firestore.FieldValue.delete(),
        locationCount3: firestore.FieldValue.delete(),
        locationCount4: firestore.FieldValue.delete(),
        locationCount5: firestore.FieldValue.delete(),
        locationDate: firestore.FieldValue.delete(),
        notification: firestore.FieldValue.delete(),
        onLocation: firestore.FieldValue.delete(),
        startLocationAt: firestore.FieldValue.delete(),
        subscribeWays: firestore.FieldValue.delete(),
        updateAt: firestore.FieldValue.delete(),
        watchPosition: firestore.FieldValue.delete(),
        watchPositionDate: firestore.FieldValue.delete(),
        coordinatesStatus: firestore.FieldValue.delete(),
        isNeedUpdate: firestore.FieldValue.delete(),
        isNetworkSpeed: firestore.FieldValue.delete(),
        platform: firestore.FieldValue.delete(),
        coordinatesType: firestore.FieldValue.delete(),
        ping: firestore.FieldValue.delete(),
        isRestart: firestore.FieldValue.delete(),
        retries: firestore.FieldValue.delete(),
        role: firestore.FieldValue.delete(),
        meter: firestore.FieldValue.delete(),
        deviceInfo: {
          uniqueId: "1",
        },
      });
    }

    data.shift();
    setDataSearchedQty((prevState) => prevState + 1);
    return limpiar(data);
  };

  const clearDriverDevices = () => {
    const drivers = [...driversData];
    return limpiar(drivers);
  };

  const onCreateReaderLocation = async () => {
    const fields = [
      { ph: "Name", phValue: "Main", name: "display_name", type: "text" },
      { ph: "Address", phValue: "1272 Valencia Street", name: "line1", type: "text" },
      { ph: "City", phValue: "San Francisco", name: "city", type: "text" },
      { ph: "State", phValue: "CA", name: "state", type: "text" },
      { ph: "Country", phValue: "US", name: "country", type: "text" },
      { ph: "Postal code", phValue: "94110", name: "postal_code", type: "text" },
    ];

    const result = await SwalWithField({
      title: "Add Stripe Reader  Location",
      fields,
      cancelText: "Cancel",
      confirmText: "Add",
    });

    const data = {
      display_name: result.data.display_name,
      address: result.data,
    };
    delete data.address.display_name;

    if (result.isConfirmed) {
      ApiClient.post("stripe/locations", data)
        .then((res) => {
          setLocationReaderList((prevState) => [res.data.location, ...prevState]);
        })
        .catch((error) => {
          Swal.fire("Error", error.message);
        });
    }
  };

  return {
    cAlert,
    setCAlert,
    loading,
    loadingSuccess,
    setLoadingSuccess,
    setOpenCAlert,
    openCAlert,
    company,
    dashboard,
    driverApp,
    riderApp,
    routingApiList,
    onChange,
    onCompanyUpdate,
    onDashboardUpdate,
    onRiderUpdate,
    onDriverUpdate,
    onRoutingApiUpdate,
    onChangeMapApi,
    dataSearchedQty,
    clearDriverDevices,
    onCreateReaderLocation,
    locationReaderList,
    selectionData,
  };
}
