import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IconButton, Stack } from "@mui/material";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CPaper from "../../../components/Custom/CPaper";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import { DriverAppModel } from "../../../utilities/model";
import MDTypography from "../../../components/MDTypography";
import { boolOptions } from "../../../utilities/data";

import CAutocomplete from "./CAutocomplete";
import { useMaterialUIController } from "../../../context";
import { useColorSelected } from "../../../utilities/func";

const input = {
  style: {
    marginBottom: 20,
  },
  inputProps: {
    autoComplete: "",
  },
  fullWidth: true,
};

export default function DriverApp({
  data = new DriverAppModel(),
  onChange,
  onClick,
  dataSearchedQty,
  clearDriverDevices,
  onCreateReaderLocation,
  locationReaderList,
}) {
  const driversData = useSelector(({ driverList }) => driverList);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { t } = useTranslation();

  return (
    <>
      <CPaper style={{ marginTop: 20, padding: 25 }}>
        <MDTypography mb={3} variant="h5">
          {t("general")}
        </MDTypography>

        <MDInput
          {...input}
          value={data.orderSubscriptionDelay}
          onChange={(e) => onChange(e, null, null, "driverApp")}
          type="number"
          name="orderSubscriptionDelay"
          label={t("orderSubscriptionDelay")}
        />

        <CAutocomplete
          value={data.hasFare}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasFare", "driverApp");
          }}
          options={boolOptions}
          label="hasFare"
        />
        {data.hasFare && (
          <MDInput
            {...input}
            value={data.fareLimit}
            onChange={(e) => onChange(e, null, null, "driverApp")}
            type="number"
            name="fareLimit"
            label={t("fareLimit")}
          />
        )}

        <CAutocomplete
          value={data.isNetwork}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isNetwork", "driverApp");
          }}
          options={boolOptions}
          label="isNetwork"
        />

        <CAutocomplete
          value={data.hasZone}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasZone", "driverApp");
          }}
          options={boolOptions}
          label="hasZone"
        />
        <CAutocomplete
          value={data.hasAutoOrders}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasAutoOrders", "driverApp");
          }}
          options={boolOptions}
          label="hasAutoOrders"
        />

        <CAutocomplete
          value={data.isOrderColorAfterRetry}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isOrderColorAfterRetry", "driverApp");
          }}
          options={boolOptions}
          label="isOrderColorAfterRetry"
        />

        <MDTypography mb={3} variant="h5">
          {t("eta")}
        </MDTypography>

        <CAutocomplete
          value={data.hasEta}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasEta", "driverApp");
          }}
          options={boolOptions}
          label="hasEta"
        />
        <CAutocomplete
          value={data.hasEtaRefresh}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasEtaRefresh", "driverApp");
          }}
          options={boolOptions}
          label="hasEtaRefresh"
        />

        {data.hasEtaRefresh && (
          <MDInput
            {...input}
            value={data.etaRefreshTime}
            onChange={(e) => onChange(e, null, null, "driverApp")}
            type="number"
            name="etaRefreshTime"
            label={t("etaRefreshTime")}
          />
        )}

        <CAutocomplete
          value={data.hasEtaSubscription}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasEtaSubscription", "driverApp");
          }}
          options={boolOptions}
          label="hasEtaSubscription"
        />
        <CAutocomplete
          value={data.hasEtaOrder}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasEtaOrder", "driverApp");
          }}
          options={boolOptions}
          label="hasEtaOrder"
        />

        <MDTypography mb={3} mt={3} variant="h5">
          {t("limitations")}
        </MDTypography>

        <CAutocomplete
          value={data.hasTimeAfterPickup}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasTimeAfterPickup", "driverApp");
          }}
          options={boolOptions}
          label="hasTimeAfterPickup"
        />
        {data.hasTimeAfterPickup && (
          <MDInput
            {...input}
            value={data.timeAfterPickup}
            onChange={(e) => onChange(e, null, null, "driverApp")}
            type="number"
            name="timeAfterPickup"
            label={t("timeAfterPickup")}
          />
        )}
        <MDInput
          {...input}
          value={data.timeReadyPickup}
          onChange={(e) => onChange(e, null, null, "driverApp")}
          type="number"
          name="timeReadyPickup"
          label={t("timeReadyPickup")}
        />

        <MDTypography mb={3} mt={3} variant="h5">
          {t("order")}
        </MDTypography>

        <CAutocomplete
          value={data.hasPrivateNotes}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasPrivateNotes", "driverApp");
          }}
          options={boolOptions}
          label="hasPrivateNotes"
        />
        <CAutocomplete
          value={data.isHiddenPhone}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isHiddenPhone", "driverApp");
          }}
          options={boolOptions}
          label="isHiddenPhone"
        />
        <CAutocomplete
          value={data.hasFullAddress}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasFullAddress", "driverApp");
          }}
          options={boolOptions}
          label="hasFullAddress"
        />
        <CAutocomplete
          value={data.hasStreet}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasStreet", "driverApp");
          }}
          options={boolOptions}
          label="hasStreet"
        />
        <CAutocomplete
          value={data.hasMarkerStreet}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasMarkerStreet", "driverApp");
          }}
          options={boolOptions}
          label="hasMarkerStreet"
        />
        <CAutocomplete
          value={data.hasCallClient}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasCallClient", "driverApp");
          }}
          options={boolOptions}
          label="hasCallClient"
        />

        <CAutocomplete
          value={data.hasLastOrderClient}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasLastOrderClient", "driverApp");
          }}
          options={boolOptions}
          label="hasLastOrderClient"
        />

        <CAutocomplete
          value={data.hasReturnOrder}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasReturnOrder", "driverApp");
          }}
          options={boolOptions}
          label="hasReturnOrder"
        />

        <MDInput
          {...input}
          value={data.timeReturnOrder}
          onChange={(e) => onChange(e, null, null, "driverApp")}
          type="number"
          name="timeReturnOrder"
          label={t("timeReturnOrder")}
        />

        <MDTypography mb={3} mt={3} variant="h5">
          {t("geofence")}
        </MDTypography>

        <MDInput
          {...input}
          value={data.geofenceRadius}
          onChange={(e) => onChange(e, null, null, "driverApp")}
          type="number"
          name="geofenceRadius"
          label={t("geofenceRadius")}
          InputProps={{
            inputProps: { min: 200, max: 2000 },
          }}
        />

        <MDTypography mb={3} mt={3} variant="h5">
          {t("request")}
        </MDTypography>

        <CAutocomplete
          value={data.isRequestCallClient}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isRequestCallClient", "driverApp");
          }}
          options={boolOptions}
          label="isRequestCallClient"
        />
        <CAutocomplete
          value={data.isRequestCancellation}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isRequestCancellation", "driverApp");
          }}
          options={boolOptions}
          label="isRequestCancellation"
        />
        <CAutocomplete
          value={data.isRequestPassOrder}
          onChange={(event, newValue) => {
            onChange(event, newValue, "isRequestPassOrder", "driverApp");
          }}
          options={boolOptions}
          label="isRequestPassOrder"
        />

        <MDTypography mb={3} mt={3} variant="h5">
          Stripe {t("and")} {t("payments")}
        </MDTypography>
        <MDInput
          {...input}
          value={data.stripePublishingKey}
          onChange={(e) => onChange(e, null, null, "driverApp")}
          name="stripePublishingKey"
          label={t("stripePublishingKey")}
        />
        <MDInput
          {...input}
          value={data.stripeMerchantId}
          onChange={(e) => onChange(e, null, null, "driverApp")}
          name="stripeMerchantId"
          label={t("stripeMerchantId")}
        />

        <CAutocomplete
          value={data.hasPayment}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasPayment", "driverApp");
          }}
          options={boolOptions}
          label="hasPayment"
        />

        <Stack mb={1} mt={5} direction="row" alignItems="center">
          <MDTypography variant="h6">
            Stripe {t("reader")} {t("locations")}
          </MDTypography>

          <IconButton onClick={onCreateReaderLocation} size="medium">
            <AddLocationAltIcon
              style={{ color: darkMode ? "#eeeeee" : "#455a64" }}
              fontSize="medium"
            />
          </IconButton>
        </Stack>

        <Stack mb={5} direction="column" alignItems="flex-start">
          {locationReaderList.map((location) => (
            <Stack
              onClick={() =>
                onChange(
                  { target: { name: "stripeLocationId", value: location.id } },
                  null,
                  null,
                  "driverApp"
                )
              }
              style={{
                cursor: "pointer",
                padding: 3,
                borderRadius: 3,
                background: useColorSelected(darkMode, data.stripeLocationId === location.id, true),
              }}
              mb={1}
              key={location.id}
              direction="row"
              alignItems="center"
            >
              <LocationOnIcon
                style={{
                  color: useColorSelected(
                    darkMode,
                    data.stripeLocationId === location.id,
                    false,
                    true
                  ),
                }}
                fontSize="medium"
              />
              <MDTypography
                ml={1}
                variant="b"
                style={{
                  fontSize: 15,
                  color: useColorSelected(
                    darkMode,
                    data.stripeLocationId === location.id,
                    false,
                    true
                  ),
                }}
              >
                {location.display_name} | {location?.address?.line1} | {location?.address?.city}
              </MDTypography>
            </Stack>
          ))}
        </Stack>

        <MDTypography mb={3} mt={3} variant="h6">
          {t("hasPayCabs")}
        </MDTypography>

        <CAutocomplete
          value={data.hasPayCabs}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasPayCabs", "driverApp");
          }}
          options={boolOptions}
          label="hasPayCabs"
        />

        <CAutocomplete
          value={data.hasPaypalForDriverPay}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasPaypalForDriverPay", "driverApp");
          }}
          options={boolOptions}
          label="Paypal"
        />

        <CAutocomplete
          value={data.hasStripeForDriverPay}
          onChange={(event, newValue) => {
            onChange(event, newValue, "hasStripeForDriverPay", "driverApp");
          }}
          options={boolOptions}
          label="Stripe"
        />
      </CPaper>

      {/* <MDButton */}
      {/*  style={{ marginTop: 40 }} */}
      {/*  size="large" */}
      {/*  fullWidth */}
      {/*  onClick={clearDriverDevices} */}
      {/*  variant="gradient" */}
      {/*  color="info" */}
      {/* > */}
      {/*  {t("clear drivers")} {driversData.length} : {dataSearchedQty} */}
      {/* </MDButton> */}

      <MDButton
        style={{ marginTop: 40 }}
        size="large"
        fullWidth
        onClick={onClick}
        variant="gradient"
        color="info"
      >
        {t("update")}
      </MDButton>
    </>
  );
}
