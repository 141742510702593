import ApartmentIcon from "@mui/icons-material/Apartment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PersonIcon from "@mui/icons-material/Person";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PinDropIcon from "@mui/icons-material/PinDrop";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import * as React from "react";
import { DateTime } from "luxon";

export const languageOptions = [
  { label: "english", id: "en" },
  { label: "spanish", id: "es" },
];

export const boolOptions = [true, false];
export const timesOptions = [0, 60, 300, 600, 900, 1200, 1500, 1800];
export const suspendOptions = [1800, 3600, 7200, 10800, 14400, 18000, 21600, 43200, 86400];

export const routesOption = [
  {
    name: "Orders",
    key: "orders",
  },
  {
    name: "Help support",
    key: "help_support",
  },
  {
    name: "Credit Card Payment",
    key: "credit_card_payment",
  },
  {
    name: "Map",
    key: "map",
  },
  {
    name: "Drivers",
    key: "drivers",
  },
  {
    name: "Client",
    key: "client",
  },
  {
    name: "Staff",
    key: "staff",
  },
  {
    name: "Payments",
    key: "payments",
  },
  {
    name: "Reports",
    key: "reports",
  },
  {
    name: "Setup",
    key: "setup",
  },
  {
    name: "Profile",
    key: "profile",
  },
];

export const countryOptions = ["us", "do"];
export const dateOptions = [
  "today",
  "yesterday",
  "current week",
  "last week",
  "current month",
  "last month",
];

export const roleOptions = ["admin", "dispatcher"];
export const statusOptions = ["active", "disabled"];

export const selectionTypeOptions = ["address", "zone"];
export const autocompleteTypeOptions = ["geoapify", "gmaps", "here", "mapbox"];

export const autocompleteTypeDriverOptions = ["Here", "Geoapify", "Mapbox", "OSRM"];

const style = { width: 30, height: 30 };
export const setupLabelTabs = [
  "company",
  "dashboard",
  "driver_app",
  "rider_app",
  "price_list",
  "routing_api",
  "routing_api_driver",
  "services",
];
export const setupIconTabs = [
  <ApartmentIcon style={style} />,
  <DashboardIcon style={style} />,
  <DirectionsCarIcon style={style} />,
  <PersonIcon style={style} />,
  <MonetizationOnIcon style={style} />,
  <PinDropIcon style={style} />,
  <PinDropIcon style={style} />,
];

export const devicesLabelTabs = ["pending", "active"];
export const devicesIconTabs = [
  <MobileOffIcon style={style} />,
  <MobileFriendlyIcon style={style} />,
];

export const vehicleTypeOptions = ["car", "mini van"];

export function RangeDateData(date) {
  let dt = DateTime;

  if (date === "today") {
    return {
      from: dt.fromObject({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
      to: dt.fromObject({ hour: 23, minute: 59, second: 59, millisecond: 59 }).toJSDate(),
    };
  }
  if (date === "yesterday") {
    return {
      from: dt
        .fromObject({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .minus({ days: 1 })
        .toJSDate(),
      to: dt
        .fromObject({ hour: 23, minute: 59, second: 59, millisecond: 59 })
        .minus({ days: 1 })
        .toJSDate(),
    };
  }
  if (date === "current week") {
    return {
      from: dt.now().startOf("week").toJSDate(),
      to: dt.now().endOf("week").toJSDate(),
    };
  }
  if (date === "last week") {
    return {
      from: dt.now().startOf("week").minus({ weeks: 1 }).toJSDate(),
      to: dt.now().endOf("week").minus({ weeks: 1 }).toJSDate(),
    };
  }
  if (date === "current month") {
    return {
      from: dt.now().startOf("month").toJSDate(),
      to: dt.now().endOf("month").toJSDate(),
    };
  }
  if (date === "last month") {
    return {
      from: dt.now().startOf("month").minus({ months: 1 }).toJSDate(),
      to: dt.now().endOf("month").minus({ months: 1 }).toJSDate(),
    };
  }
}
